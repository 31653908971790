<template>
  <div class="bg">
    <div class="wrapper">
      <div>
    <div _ngcontent-c6="" class="page1">
      <h2 _ngcontent-c6="" class="center" style="font-size: 36px;">银行</h2>
      <h3 _ngcontent-c6="" class="left">用户背景</h3>
      <p _ngcontent-c6="" class="em2">某银行是一家中国领先的大型商业银行，总部设立在北京。该银行为客户提供个人银行业务、公司银行业务、投资理财等全面的金融服务，设有上万个分支机构。在基金、租赁、信托、保险、期货、养老金、投行等多个行业拥有子公司，境外机构覆盖多个国家和地区，拥有各级境外机构上百家。</p>
      <h3 _ngcontent-c6="" class="left">需求痛点</h3>
      <p _ngcontent-c6="" class="em2">长期以来，金融行业对于信息安全系统产品性能和稳定性的要求很高，造成了我国金融领域用户一定程度上对国外信息安全产品的依赖。在DLP数据防泄露产品方面，由于产品技术门槛较高，我国部分银行特别是大型银行用户过去只能选择国外品牌产品。在使用国外DLP产品一段时间后，很多用户发现其产品不仅无法满足自主知识产权需求，还难以及时响应我国银行特有的数据防护需求，国外品牌产品大多是根据国外用户的需求来研制，在我国企业中实际部署时，经常会出现“水土不服”的情况。</p>
      <p _ngcontent-c6="" class="em2">在早期使用国外品牌DLP产品时，该行科技部门认为产品在满足自身特点的定制化应用上存在较大不足，希望通过部署高性能国产敏感信息泄露防护产品替换国外品牌。科技部门要求防护系统不仅能对行内敏感数据泄露行为进行有效的防护,同时要加强对于使用私有协议传输数据的应用软件进行监控，是否有敏感信息传输，并在接口开放、功能和性能上满足定制化需求。</p>
      <p _ngcontent-c6="" class="em2">基于该银行的业务形态，该行科技部门对国产高性能敏感信息泄露防护系统的主要需求如下：</p>
      <p _ngcontent-c6="" class="em2">1.构建基于网络、代理、存储、邮件、终端、网间交换的整体数据安全防护体系；</p>
      <p _ngcontent-c6="" class="em2">2.形成以客户数据为核心的敏感数据防护能力；</p>
      <p _ngcontent-c6="" class="em2">3.完善该银行数据合规与保护的能力体系建设。</p>
      <p _ngcontent-c6="" class="em2">经过多轮严格的功能性和适用性测试，天空卫士数据防泄露解决方案凭借完善的产品功能和出色的技术性能，最终获得该银行的认可。银行在业务开展过程中，会产生大量的敏感信息。为满足该银行在数据安全方面的需求，天空卫士以《网络安全法》监管要求为依据，深入分析用户的实际应用场景，为用户量身打造数据安全防护整体解决方案，让该行可以快速简单地了解信息资产的安全状况，同时发现内部潜在的泄密风险，规避数据泄露事件发生。</p>
      <h3 _ngcontent-c6="" class="left">解决方案</h3>
      <p _ngcontent-c6="" class="em2">整体解决方案涉及总行邮件出口、总行及全国30多个一级分行的互联网出口、办公终端以及网间数据交换平台。</p>
      <p _ngcontent-c6="" class="em2 bold">系统整体部署架构如下：</p>
      <div _ngcontent-c6="" class="img">
        <img _ngcontent-c6="" alt="" src="../../assets/images/djt/sPage1-0.png">
      </div>
  </div>
  </div>
    </div>
  </div>
  
</template>

<style lang="less" scoped>
  .left {
    margin: 20px 0;
    font-size: 22px;
  }
  .em2[_ngcontent-c6]{
  text-indent: 2em;
  line-height: 40px;
}
.center[_ngcontent-c6]{
  text-align: center;
}
.img {
  width: 100%;
  display: flex;
  padding: 10px 0;
  align-items: center;
  justify-content: center;
}
.img img {
  width: 600px;
}
  .bg {
    color: #333;
    font-size: 16px;
    background: #F7F6FA;
    padding: 60px 0;
    .activity {
      background: #fff;
      padding: 20px;
    }
  }
  .text-title {
    font-size: 24px;
    font-weight: bold;
    color:#333;
    text-align: center;
    margin-bottom: 18px;

  }
  .title {
    margin-right: 7px;
    font-size: 18px;
    color: #217aff;
    h6 {
      font-size: 22px;
  margin-right: 7px;

    }
  }
  .intro {
    text-indent: 2em;
  line-height: 40px;
  font-size: 16px;
  color: #333;

  }
  
  
  
  </style>
